import PropTypes from 'prop-types'
import React from 'react'

import styles from './ATile.module.scss'

const classNames = require('classnames')

const ATile = ({ childDefault, childHover, link, ...props }) => {
  const TileTag = link ? `a` : `div`
  const classesTile = classNames({
    [styles.tile]: true,
    [styles.tile__link]: link,
  })

  return (
    <li className={classesTile} {...props}>
      <TileTag
        href={link || false}
        target={link ? '_blank' : false}
        rel={link ? 'noopener noreferrer' : false}
        className={styles.tileInner}
      >
        <div className={styles.itemDefault}>{childDefault}</div>
        {childHover ? (
          <div className={styles.itemHover}>{childHover}</div>
        ) : (
          false
        )}
      </TileTag>
    </li>
  )
}

ATile.propTypes = {
  childDefault: PropTypes.node,
  childHover: PropTypes.node,
  link: PropTypes.string,
}

ATile.defaultProps = {
  childDefault: false,
  childHover: false,
  link: false,
}

export default ATile
