import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'

import ATile from '../components/01-atoms/ATile'
import AType from '../components/01-atoms/AType'
import ATileContainer from '../components/01-atoms/ATileContainer'

import OIntro from '../components/03-organisms/OIntro'

import styles from './marken.module.scss'

// brand assets
import brandJulbo from '../assets/brands/julbo.svg'
import brandProdesigndenmark from '../assets/brands/prodesigndenmark.svg'
import brandResrei from '../assets/brands/resrei.svg'

const BrandImg = ({ file, ...props }) => (
  <Img
    fluid={file}
    className={styles.brandImg}
    backgroundColor="#fff"
    {...props}
  />
)
BrandImg.propTypes = {
  file: PropTypes.shape.isRequired,
}
const BrandImgSvg = ({ file, ...props }) => (
  <img
    src={file}
    className={styles.brandImg}
    backgroundColor="#fff"
    {...props}
  />
)
BrandImgSvg.propTypes = {
  file: PropTypes.string.isRequired,
}

const IndexPage = ({ data }) => console.log(data) || (
  <Layout>
    <Helmet>
      <title>Marken</title>
      <meta
        name="description"
        content="Unsere Marken beim Augenoptiker »Optique - Meisterhandwerk und Brillenmanufaktur«: Aigner, Andy Wolf, Bellinger, Einstoffen, Entourage of 7, Head, Liebeskind, More and More, sOliver, Whisky and Candy"
      />
    </Helmet>

    <OIntro
      title={
        <AType type="h1" className="title">
          Unsere Marken
        </AType>
      }
      subtitle={
        <AType type="h2" className="subtitle">
          Optique – Meisterhandwerk und Brillenmanufaktur
          <br /> Augenoptiker in Stuttgart-Süd
        </AType>
      }
    />

    <Img
      fluid={data.shop.childImageSharp.fluid}
      className="content-img"
      backgroundColor="#29332c"
      alt="Brillenauswahl beim Optiker Optique Stuttgart"
    />

    <AType align="center" className="lead">
      Ständig auf der Suche nach neuen Trends sichten wir auf Mode- und
      Fachmessen die Kollektionen bekannter und unbekannter Designer. Nur wenn
      die Brillenfassungen in das Gesamtkonzept der Optique passen nehmen wir
      diese ins Sortiment. Qualität, kurze Wege zwischen Lieferanten und
      Optiker, transparente Fertigungsprozesse, Materialien aus bewusster
      Herstellung – einige Eckpunkte die uns wichtig sind. Um ein breites
      Spektrum bieten zu können sind sowohl exklusive Designerlabels als auch
      preisbewusstere Modelle in der Optique zu entdecken. Jeder soll seinen
      Bedürfnissen entsprechend die passende Brille finden.
      <br />
      Aktuelle Impressionen auf{' '}
      <a
        href="https://www.instagram.com/optique_stuttgart/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Instagram
      </a>{' '}
      oder{' '}
      <a
        href="https://www.facebook.com/optiquestuttgart/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Facebook
      </a>
      .
    </AType>

    <ATileContainer>
      <ATile
        link="https://www.andy-wolf.com/"
        childDefault={
          <BrandImg
            file={data.andywolf.childImageSharp.fluid}
            alt="Andy Wolf Eyewear"
          />
        }
      />
      <ATile
        link="https://brett-eyewear.com/"
        childDefault={
          <BrandImg
            file={data.brett.childImageSharp.fluid}
            alt="BRETT eyewear | optical glasses and sunglasses for men"
          />
        }
      />
      <ATile
        link="https://www.einstoffen.ch/de/home/"
        childDefault={
          <BrandImg
            file={data.einstoffen.childImageSharp.fluid}
            alt="Einstoffen"
          />
        }
      />
      <ATile
        link="https://eof7.com/"
        childDefault={
          <BrandImg
            file={data.entourageof7.childImageSharp.fluid}
            alt="Entourage of 7 Eyewear | Luxury frames based in Los Angeles"
          />
        }
      />
      <ATile
        link="https://funkeyewear.com/"
        childDefault={
          <BrandImg
            file={data.funkeyewear.childImageSharp.fluid}
            alt="FUNK eyewear"
            style={{ maxWidth: "200px" }}
          />
        }
      />
      <ATile
        link="https://www.julbo.com/de_de/"
        childDefault={
          <BrandImgSvg
            file={brandJulbo}
            style={{ maxWidth: "200px" }}
            alt="Sonnenbrillen, Skibrillen und Helme | Julbo®  - Julbo.com"
          />
        }
      />
      <ATile
        link="https://kaleoscollection.com/de_DE/"
        childDefault={
          <BrandImg
            file={data.kaleos.childImageSharp.fluid}
            style={{ maxWidth: "80%" }}
            alt="Kaleos"
          />
        }
      />
      <ATile
        link="https://www.prodesigndenmark.com/"
        childDefault={
          <BrandImgSvg
            file={brandProdesigndenmark}
            style={{ maxWidth: "200px" }}
            alt="Prodesign Denmark"
          />
        }
      />
      <ATile
        link="https://raen.com"
        childDefault={
          <BrandImg file={data.raen.childImageSharp.fluid} alt="RAEN" />
        }
      />
      <ATile
        link="https://www.resrei.com/en/"
        childDefault={
          <BrandImgSvg
            file={brandResrei}
            style={{ maxWidth: "200px" }}
            alt="Res Rei | Handmade in Italy with Love"
          />
        }
      />
    </ATileContainer>
  </Layout>
)

IndexPage.propTypes = {
  data: PropTypes.shape.isRequired,
}

export default IndexPage

export const brandImg = graphql`
  fragment BrandImg on File {
    childImageSharp {
      fluid(
        maxWidth: 280
        excludeOriginal: true
        duotone: { highlight: "#ffffff", shadow: "#000000" }
        toFormat: PNG
      ) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`
export const query = graphql`
  query {
    shop: file(
      relativePath: { eq: "shop/augenoptiker_optique__MG_2892_3_4_01.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900, excludeOriginal: true) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    andywolf: file(relativePath: { eq: "brands/andywolf.png" }) {
      ...BrandImg
    }
    brett: file(relativePath: { eq: "brands/brettneoheroes.png" }) {
      ...BrandImg
    }
    einstoffen: file(relativePath: { eq: "brands/einstoffen.png" }) {
      ...BrandImg
    }
    entourageof7: file(relativePath: { eq: "brands/entourageof7.png" }) {
      ...BrandImg
    }
    funkeyewear: file(relativePath: { eq: "brands/funkeyewear.png" }) {
      ...BrandImg
    }
    kaleos: file(relativePath: { eq: "brands/kaleos.png" }) {
      ...BrandImg
    }
    raen: file(relativePath: { eq: "brands/raen.png" }) {
      ...BrandImg
    }
  }
`
