import PropTypes from 'prop-types'
import React from 'react'

import styles from './ATileContainer.module.scss'

const classNames = require('classnames')

const ATileContainer = ({ children, ...props }) => (
  <ul className={styles.container} {...props}>
    {children}
  </ul>
)

ATileContainer.propTypes = {
  children: PropTypes.oneOf([PropTypes.node, PropTypes.boolean]),
}

ATileContainer.defaultProps = {
  children: PropTypes.node,
}

export default ATileContainer
